<template>
    <div>
        <div v-if="themeinfo != null">
            <section id="page-header">
                <div class="page-header-image parallax-bg-3 bg-image"
                    :style="'background-image: url(http://admin.artandme.cn/' + themeinfo.maxImage + ');'">
                    <div class="cover bg-transparent-5-dark"></div>
                </div>

                <div class="page-header-inner tt-wrap">
                    <div class="page-header-caption ph-caption-lg parallax-5 fade-out-scroll-3">
                        <h1 class="page-header-title">{{ themeinfo.name }}</h1>
                        <hr class="hr-short">
                        <div class="page-header-description" data-max-words="40">
                            <p>{{ themeinfo.abstract }}</p>
                        </div>

                    </div>


                </div>


            </section>
        </div>

        <section id="gallery-list-section">
            <div class="tt-wrap" style="margin-top:2%">
                <span><a href="/" style="color:#fff;font-size: 16px;">首页</a></span>
                <span><img src="static/picture/back.png" style="width:20px"></span>
                <span><a style="color:#fff;font-size: 18px;">主题</a></span>
            </div>
            <div class="search-item-title">
                <div id="big-search-form" class="form-btn-inside">
                    <div class="big-search-input-wrap">
                        <input type="text" id="big-search-input" v-model="keywords" name="search"
                            placeholder="作品名称、作者、机构">
                        <button @click="GetsearchOpusList(false)"><i class="fas fa-search fa-search-btn"></i></button>
                    </div>
                </div>
            </div>

            <div class="isotope-wrap tt-wrap">

                <div class="isotope col-4 gutter-3">
                    <div class="isotope-top-content" v-if="themeinfo != null">
                        <div class="isotope-filter">
                            <div class="isotope-filter-button">
                                <span class="ifb-icon"><i class="fas fa-sliders-h"></i></span>
                                <span class="ifb-icon-close"><i class="fas fa-times"></i></span>

                            </div>
                            <ul class="isotope-filter-links">
                                <li><a @click="activetype(0)" href="#" class="active" data-filter="*">全部</a></li>
                                <li v-for="(item, index) in themeinfo.opusTypeNames.split(',')" :key="index">
                                    <a @click="activetype(themeinfo.opusTypeIds.split(',')[index])"
                                        :data-filter="'.' + themeinfo.opusTypeIds.split(',')[index]">{{
                                                item
                                        }}</a>
                                </li>
                            </ul>
                        </div>


                    </div>

                    <div class="isotope-items-wrap gli-colored">
                        <div class="grid-sizer"></div>
                        <div :class="'isotope-item ' + item.opusTypeID + ' iso-height-1'"
                            v-for="(item, indexx) in opuslist" :key="indexx">
                            <div class="gallery-list-item">
                                <div class="gl-item-image-wrap">
                                    <a target="_blank" :href="'opusinfo?id=' + item.id" class="gl-item-image-inner">
                                        <div class="gl-item-image bg-image"
                                            v-lazy:background-image="'http://admin.artandme.cn/' + item.coverPicture"
                                            style=" background-position: 50% 50%">
                                        </div>
                                        <span class="gl-item-image-zoom"></span>
                                    </a>
                                    <ul class="gli-meta">
                                        <li>
                                            <div class="favorite-btn">
                                                <div class="fav-inner">
                                                    <!-- <div class="icon-heart">
                                                        <span class="icon-heart-empty"></span>
                                                        <span class="icon-heart-filled"></span>
                                                    </div> -->
                                                    <div style="position: relative;top: 2px;right: 5px;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-eye">
                                                            <path
                                                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                            <path
                                                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div class="fav-count">{{ item.scanCount }}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="gl-item-info">
                                    <div class="gl-item-caption">
                                        <h2 class="gl-item-title"><a target="_blank" :href="'opusinfo?id=' + item.id">{{
                                                item.opusName
                                        }}</a></h2>
                                        <span class="gl-item-category"><a target="_blank"
                                                :href="'opusinfo?id=' + item.id">{{
                                                        item.userName
                                                }}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>

<script>

export default {
    name: 'Home',
    components: {
        // HelloWorld
    },
    data() {
        return {
            themeinfo: null,
            active: '*',
            opuslist: [],
            thids: 0,
            keywords: '',
            tyinfo: -1,
            pagesize: 12,
            pageindex: 1,
            totalpage: 0,
            typeid: 0,
            isend: true
        }

    },
    created() {
        var id = Number(this.$route.query.id);
        this.thids = id;
        this.GetThemeInfo(id)
    },
    mounted() {
        let arr = [
            'static/js/bootstrap.min.js',
            'static/js/animsition.min.js',
            'static/js/jquery.easing.min.js',
            'static/js/isotope.pkgd.min.js',
            'static/js/imagesloaded.pkgd.min.js',
            'static/js/owl.carousel.min.js',
            'static/js/jquery.mousewheel.min.js',
            'static/js/jquery.mb.YTPlayer.min.js',
            'static/js/lightgallery-all.min.js',
        ];
        arr.map((item) => {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = item;
            document.getElementsByTagName('body')[0].appendChild(script);
        });
        window.addEventListener('scroll', this.scrollFn);

    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollFn); // 销毁监听
    },
    methods: {
        GetThemeInfo(id) {
            this.$httphelper.get('OpusApi/GetOpusThemeInfoById', { id: id }).then(res => {
                if (res.code == 200) {
                    this.themeinfo = res.data;
                    this.GetsearchOpusList(true)

                }

            });
        },
        GetOpusList(thid) {
            this.$httphelper.get('OpusApi/GetUserOpusList', {
                thid: thid,
                type: 0,
                keywords: this.keywords,
                pageindex: this.pageindex,
                pagesize: this.pagesize

            }
            ).then(res => {
                if (res.code == 200) {
                    this.opuslist = res.data;
                    this.cratescript();
                }

            });
        },
        GetsearchOpusList(cr) {
            if (cr == false) {
                this.typeid = 0
            }
            this.pageindex = 1
            this.opuslist = [];
            this.$httphelper.get('OpusApi/GetUserOpusList', {
                thid: this.thids,
                type: 0,
                keywords: this.keywords.trim(),
                pageindex: this.pageindex,
                pagesize: this.pagesize
            }).then(res => {
                if (res.code == 200) {

                    this.opuslist = res.data;
                    if (cr) {
                        this.cratescript();
                    }
                    this.totalpage = res.totalpage

                }

            });
        },
        cratescript() {
            let arr = [
                'static/js/theme.js',
                'static/js/demo-panel.js',
                'static/js/isotope.pkgd.min.js',
                'static/js/styleswitch.js'];
            arr.map((item) => {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = item;
                document.getElementsByTagName('body')[0].appendChild(script);
            });

        },
        getScrollTop() {
            var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
            if (document.body) {
                bodyScrollTop = document.body.scrollTop;
            }
            if (document.documentElement) {
                documentScrollTop = document.documentElement.scrollTop;
            }
            scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
            return scrollTop;
        },

        //可视窗口高度

        getWindowHeight() {
            var windowHeight = 0;
            if (document.compatMode == "CSS1Compat") {
                windowHeight = document.documentElement.clientHeight;
            }
            else {
                windowHeight = document.body.clientHeight;
            }
            return windowHeight;
        },

        //滚动条高度

        getScrollHeight() {
            var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
            if (document.body) {
                bodyScrollHeight = document.body.scrollHeight;
            }
            if (document.documentElement) {
                documentScrollHeight = document.documentElement.scrollHeight;
            }
            scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
            return scrollHeight;
        },
        scrollFn() {

            if (this.pageindex < this.totalpage) {
                if ((this.getScrollTop() + this.getWindowHeight()) + 120 >= this.getScrollHeight()) {
                    // 这里执行动态获取数据的函数
                    this.GetscrollOpusList();
                }
            }
        },
        GetscrollOpusList() {
            this.pageindex = this.pageindex + 1;
            this.$httphelper.get('OpusApi/GetUserOpusList', {
                thid: this.thids,
                type: this.typeid,
                keywords: this.keywords.trim(),
                pageindex: this.pageindex,
                pagesize: this.pagesize
            }).then(res => {
                if (res.code == 200) {
                    this.opuslist = this.opuslist.concat(res.data);
                    this.totalpage = res.totalpage
                }

            });
        },

        activetype(typeid) {
            this.opuslist = []
            this.pageindex = 0
            this.totalpage = 0
            this.typeid = typeid
            this.GetscrollOpusList()
        }

    }
}
</script>
<style>
.search-item-title {
    padding-top: 20px;
    width: 60%;
    padding-left: 13%;
}

@media screen and (max-width: 400px) {
    .search-item-title {
        width: 95%;
        padding: 5%;
        padding-bottom: 0%;
    }
}

.ttttdis {
    display: none;
}
</style>